/* eslint-disable */
import React from 'react'
import Agent from '../images/people/customer-service-agent.png'
import GroupOfPeople from '../images/people/group-of-people.png'
import grayImgBackground from '../images/backgrounds/gray-img-background.jpg'
import dottedPurpleBackground from '../images/backgrounds/dotted-purple-background.jpg'
import header from '../components/Header'
import footer from '../components/Footer'
import {
  Billboard,
  Carousel,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import NarrowBanner from '../components/NarrowBanner'

const Home = () => {
  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Clearlink Insurance Agency | Insurance Quotes | 833-488-0038',
          description:
            'About Clearlink Insurance Agency – compare Supplemental Health and Medicare Insurance plans from the top Insurance providers with help from unbiased, licensed agent.',
          canonical: 'https://clearlinkinsurance.com/about-us',
          robots: 'follow,index',
        },
        path: '/about-us',
        promoCode: '169446',
        ringPool: 'CLI',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlinkinsurance.com/',
          siteName: 'clearlinkinsurance',
          alternateName: 'clearlinkinsurance',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'About Us | Clearlink Insurance Agency  | 833-488-0038',
    defaultRobots: 'follow,index',
    main: (
      <>
        <VariableContent
          mainContent={
            <>
              <Typography variant="h1">
                About Clearlink Insurance Agency
              </Typography>
            </>
          }
          alignMainContent="Center"
          className="dotted-purple-background"
          style={{
            backgroundImage: `url(${dottedPurpleBackground})`,
            color: '#fff',
          }}
        ></VariableContent>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          image={<img src={GroupOfPeople} alt="group of people" />}
          mobileImage={false}
          mainContent={
            <>
              <Typography variant="h2">Our Mission</Typography>
              <Typography variant="body" className="large text-left-on-small">
                We understand that navigating the complexities of insurance can
                be overwhelming, which is why we stand ready to provide
                unparalleled support, guidance, and assistance at every step of
                the journey. Our ultimate goal is to foster trust and build
                lasting relationships, ensuring that our clients feel valued,
                secure, and confident in their insurance decisions.
              </Typography>
            </>
          }
        />

        <div className="gray-img-background">
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2" className="large blue-text">
                  10+ Years of Experience
                </Typography>
                <Typography variant="body" className="text-left-on-small">
                  Since 2017, we’ve helped more than 100,000 people find the
                  right healthcare insurance in all 50 states. With more than
                  300 U.S.-based licensed agents, Clearlink Insurance is
                  positioned to help you find coverage for you and your family.
                </Typography>
              </>
            }
            alignMainContent="Center"
            style={{
              backgroundImage: `url(${grayImgBackground})`,
            }}
          ></VariableContent>
        </div>

        <div className="purple-triangle-background">
          <Billboard
            alignImageToBottom
            className="purple-triangle-background"
            variant="split"
            image={
              <img
                src={Agent}
                className="margin-bottom-5"
                alt="customer service agent"
              />
            }
            mobileImage={
              <img
                src={Agent}
                className="margin-bottom-5"
                alt="customer service agent"
              />
            }
            mainContent={
              <>
                <Typography variant="h1" className="text-center">
                  We Know What We're Doing
                </Typography>
                <List className="text-center-on-small">
                  <ListItem>
                    We have more than 300 licensed agents nationwide.
                  </ListItem>
                  <ListItem>
                    We work with the top insurance industries in the country,
                    including Aetna, Cigna, and Regence.
                  </ListItem>
                  <ListItem>
                    Our experts know the ins and outs of Medicare, supplemental,
                    and property & casualty insurance.
                  </ListItem>
                  <ListItem>
                    We help you narrow down the best insurance options for your
                    lifestyle and let you make the final decision.
                  </ListItem>
                </List>
              </>
            }
          />
        </div>

        <div className="client-portal-banner">
          <NarrowBanner
            backgroundColor="#F004B9"
            centeredButton={
              <LinkButton
                to="https://my.clearlinkinsurance.com/login"
                color="white"
              >
                User Portal Login
              </LinkButton>
            }
          />
        </div>

        <VariableContent
          alignMainContent="Center"
          className="quote-slide-carousel"
        >
          <Carousel>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Robert A. from Texas says
                  </Typography>
                  <Typography variant="body">
                    “...definitely went above and beyond what could be expected
                    on trying to clear up a messy situation with an insurance
                    company. When it looked like all was futile, you were able
                    to come through and provide us with the coverage we were
                    looking for.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
            <VariableContent
              className="quote-slide"
              mainContent={
                <>
                  <Typography variant="h2">
                    Joseph A. from the U.S. says
                  </Typography>
                  <Typography variant="body">
                    “The agent was informative and helpful. A good experience.”
                  </Typography>
                </>
              }
              alignMainContent="Center"
            ></VariableContent>
          </Carousel>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home
